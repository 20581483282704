const getDefaultState = () => {
  return {
    headerSection: {},
    footerSection: {},
    groupSection: {
      items: []
    },
    detailSection: {
      items: []
    }
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_HEADER_SECTION (state, payload) {
      state.headerSection = payload
    },
    SET_FOOTER_SECTION (state, payload) {
      state.footerSection = payload
    },
    SET_GROUP_SECTION (state, payload) {
      state.groupSection = payload
    },
    SET_DETAIL_SECTION (state, payload) {
      state.detailSection = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
