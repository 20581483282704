<template>
  <div class="flex flex-col no-scroll-content">
    <!------------ header ------------->
    <div class="flex-none">
      <div class="p-2 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex flex-wrap">
          <div class="px-2 sm:w-3/12 w-full">
            <div class="flex items-center text-xs">
              <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
              <vs-select class="w-full vs-select-small" v-model="headerSection.filter.id_proyek">
                <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
              </vs-select>
            </div>
          </div>
          <div class="px-2 sm:w-1/12 w-full">
            <vs-button class="px-6 py-3 text-xs text-center" color="primary" @click="filter">Filter</vs-button>
          </div>
        </div>
      </div>
    </div>

    <!----------- content ------------->
    <div class="flex-grow">
      <div class="flex h-full">
        <div class="w-full sm:w-4/12 pr-1">
          <GroupSection ref="groupSection"/>
        </div>
        <div class="w-full sm:w-8/12 pl-1">
          <DetailSection ref="detailSection"/>
        </div>
      </div>
    </div>
<!--    <div class="flex-grow">-->
<!--      <div class="flex flex-row h-full space-x-2">-->
<!--        <div class="flex-none d-theme-dark-bg" style="width: 25rem;">-->
<!--          <GroupSection ref="groupSection"/>-->
<!--        </div>-->
<!--        <div class="flex-1 d-theme-dark-bg">-->
<!--          <DetailSection ref="detailSection"/>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <!------------ footer ------------->
    <div class="flex-none vx-card p-2 rounded-none">
      <div class="flex flex-wrap items-center">
        <div class="sm:w-4/12 w-full">
          <div class="flex items-center">
            <vs-button v-if="$can('create_group_setup_fixed_asset')" :disabled="!headerSection.filter.id_proyek" color="success" size="small" class="px-3 mr-2" icon-pack="feather" icon="icon-plus" @click="showModalGroupAdd">Tambah Group</vs-button>
          </div>
        </div>
        <div class="sm:w-8/12 w-full">
          <div class="flex items-center left-auto">
            <div class="flex items-center">
              <vs-button v-if="$can('create_detail_setup_fixed_asset')" :disabled="!headerSection.filter.id_proyek || !dataGroupSection.selected" color="success" size="small" class="px-3 mr-2 ml-2" icon-pack="feather" icon="icon-plus" @click="showModalDetailAdd">Tambah Detail</vs-button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <GroupAssetAdd :isActive.sync="modalGroupAdd.active"
                   @success="filter" />

    <DetailAssetAdd :isActive.sync="modalDetailAdd.active"
                    :id-fixed-asset="modalDetailAdd.id_fixed_asset"
                    :nama-fixed-asset="modalDetailAdd.nama_fixed_asset"
                    @success="filter"/>

  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import GroupSection from '@/views/pages/accounting/setup/setup-fixed-asset/groups/GroupSection'
import DetailSection from '@/views/pages/accounting/setup/setup-fixed-asset/details/DetailSection'
import moduleSetupFixedAsset from '@/store/modules/accounting/setup-fixed-asset/setup-fixed-asset.store'
import _ from 'lodash'

export default {
  name: 'SetupFixedAsset',
  components: {
    GroupAssetAdd: () => import('@/views/pages/accounting/setup/setup-fixed-asset/GroupAssetAdd'),
    DetailAssetAdd: () => import('@/views/pages/accounting/setup/setup-fixed-asset/DetailAssetAdd'),
    GroupSection,
    DetailSection
  },
  mounted () {
    this.getProyek()
  },
  computed: {
    dataGroupSection () {
      return this.$store.state.accounting.setupFixedAsset.groupSection
    },
    dataDetailSection () {
      return this.$store.state.accounting.setupFixedAsset.detailSection
    }
  },
  data () {
    return {
      modalGroupAdd: {
        active: false
      },
      modalDetailAdd: {
        active: false,
        id_fixed_asset: null,
        nama_fixed_asset: null
      },
      headerSection: {
        filter: {
          id_proyek: null
        }
      },
      footerSection: {},
      proyeks: []
    }
  },
  watch: {
    headerSection: {
      deep: true,
      handler (value) {
        this.commitHeaderSectionToStore(value)
      }
    },
    footerSection: {
      deep: true,
      handler (value) {
        this.commitFooterSectionToStore(value)
      }
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    showModalGroupAdd () {
      this.modalGroupAdd.active = true
    },

    showModalDetailAdd () {
      this.modalDetailAdd.id_fixed_asset = this.dataGroupSection.selected.id
      this.modalDetailAdd.nama_fixed_asset = this.dataGroupSection.selected.nama
      this.modalDetailAdd.active = true
    },

    filter () {
      if (this.headerSection.filter.id_proyek) {
        this.$refs.groupSection.refresh()
      } else {
        this.notifyWarning('Proyek wajib dipilih.')
      }
    },

    commitHeaderSectionToStore (value) {
      this.$store.commit('accounting/setupFixedAsset/SET_HEADER_SECTION', _.cloneDeep(value))
    },

    commitFooterSectionToStore (value) {
      this.$store.commit('accounting/setupFixedAsset/SET_FOOTER_SECTION', _.cloneDeep(value))
    }
  },
  created () {
    this.commitHeaderSectionToStore(this.headerSection)
    this.commitFooterSectionToStore(this.footerSection)
  },
  beforeCreate () {
    this.$store.registerModule(['accounting', 'setupFixedAsset'], moduleSetupFixedAsset)
  },
  beforeDestroy () {
    this.$store.commit('accounting/setupFixedAsset/RESET_STATE')
    this.$store.unregisterModule(['accounting', 'setupFixedAsset'])
  }
}
</script>
