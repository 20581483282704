<template>
  <div class="h-full d-theme-dark-bg">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">
            {{ data.loading ? 'Loading...' : 'Group Asset' }}
          </p>
          <QueryEditor v-if="$can('view_query')" code="GROUP_FIXED_ASSET"/>
        </div>
      </div>
      <div class="h-full">
        <div class="tableSticky overflow-auto">
          <table class="w-full h-full border-collapse table-fixed">
            <thead class="font-bold">
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-4"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-16">Action</th>
                <th class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-center w-48">Nama Group</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-84">Account Debit</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-84">Account Credit</th>
              </tr>
            </thead>
            <tbody class="font-medium">
              <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium" @click="data.selected = item">
                <td class="text-xx border border-solid d-theme-border-grey-light text-center">
                  <div class="flex items-center">
                    <vs-icon v-if="item === data.selected" size="14px" color="danger" icon-pack="material-icons" icon="play_arrow"/>
                  </div>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">
                  <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer xs" v-if="$canAny(['update_group_setup_fixed_asset', 'delete_group_setup_fixed_asset'])">
                    <vs-button class="py-2 px-3 small" type="filled" icon-pack="feather" icon="icon-more-horizontal"></vs-button>
                    <vs-dropdown-menu>
                      <vs-dropdown-item class="p-1" v-if="$can('update_group_setup_fixed_asset')" @click="showModalEdit(item)"><span class="whitespace-no-wrap">Edit</span></vs-dropdown-item>
                      <vs-dropdown-item class="p-1" v-if="$can('delete_group_setup_fixed_asset')" @click="confirmDelete(item.id)"><span class="whitespace-no-wrap text-danger">Hapus</span></vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </td>
                <td class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_coa_debet }} - {{ item.nama_coa_debet }} </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.kode_coa_kredit }} - {{ item.nama_coa_kredit }}</td>
              </tr>
              <!--empty state-->
              <tr v-if="data.items.length < 1">
                <td colspan="5" class="text-xs text-center p-2">Tidak ada data.</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="5"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!--modals-->
    <GroupAssetEdit :isActive.sync="modalGroupEdit.active" :idGroupAsset="modalGroupEdit.item.id" @success="getData"/>

  </div>
</template>

<script>
import SetupFixedAssetRepository from '@/repositories/accounting/setup-fixed-asset-repository'
import _ from 'lodash'

export default {
  name: 'GroupSection',
  components: {
    GroupAssetEdit: () => import('@/views/pages/accounting/setup/setup-fixed-asset/GroupAssetEdit'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.accounting.setupFixedAsset.headerSection
    }
  },
  data () {
    return {
      modalGroupEdit: {
        active: false,
        item: {}
      },
      data: {
        loading: false,
        selected: null,
        items: []
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.$store.commit('accounting/setupFixedAsset/SET_GROUP_SECTION', _.cloneDeep(value))
      }
    }
  },
  methods: {
    getData () {
      this.data.loading = true

      const params = { id_proyek: this.dataHeaderSection.filter.id_proyek }
      SetupFixedAssetRepository.getGroups(params)
        .then(response => {
          this.data.items = response.data.data
          if (this.data.items.length > 0) {
            this.data.selected = this.data.items[0]
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    delete (id) {
      SetupFixedAssetRepository.deleteGroup(id)
        .then(response => {
          this.getData()
          this.notifySuccess('Data berhasil terhapus')
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
    },

    showModalEdit (item) {
      this.modalGroupEdit.item = item
      this.modalGroupEdit.active = true
    },

    refresh () {
      this.data.items = []
      this.data.selected = null
      this.getData()
    },

    confirmDelete (id) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Konfirmasi',
        text: 'Yakin ingin hapus data?',
        acceptText: 'Hapus',
        cancelText: 'Batal',
        accept: () => { this.delete(id) }
      })
    }

  }
}
</script>

<style>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 305px);
}

.tableSticky thead tr {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.tableSticky tfoot tr {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
