import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/setupFixedAsset'

export default {
  getGroups (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/groups${queryParams}`)
  },

  showGroup (idGroupAsset) {
    return httpClient.get(`${endpoint}/groups/${idGroupAsset}`)
  },

  createGroup (params) {
    return httpClient.post(`${endpoint}/groups`, params)
  },

  updateGroup (params) {
    return httpClient.patch(`${endpoint}/groups`, params)
  },

  deleteGroup (id) {
    return httpClient.delete(`${endpoint}/groups/${id}`)
  },

  getDetails (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/details${queryParams}`)
  },

  showDetail (idDetailAsset) {
    return httpClient.get(`${endpoint}/details/${idDetailAsset}`)
  },

  createDetail (params) {
    return httpClient.post(`${endpoint}/details`, params)
  },

  updateDetail (params) {
    return httpClient.patch(`${endpoint}/details`, params)
  },

  deleteDetail (id) {
    return httpClient.delete(`${endpoint}/details/${id}`)
  }
}
